import React from 'react';
import PropTypes from 'prop-types';
import { setLocaleCookie } from "../../checkout/helpers/cookie";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setJsLocale, setServerLocale, changeLocale } from "@/actions/intl";
import Translations from '@h/translation';
import Translation from '@s/Translation';
import Spinner from '@s/Spinner';
import KioskableComponent from '../../checkout/views/kiosk/KioskableComponent';
import { isKioskMode } from '../../checkout/selectors/ui';

class SelectLanguage extends KioskableComponent {
    state = {
        loading: false,
    }

    async onSelectLanguage(serverLocale, locale) {
        if (this.state.loading) {
            return true;
        }

        this.setState({loading: true});
        await this.props.changeLocale(serverLocale, locale);
        this.setState({loading: false});
        this.props.setLanguageModal(false);
    }

    render() {
        if(this.props.isEmbed || !this.props.languages || this.props.languages.length < 2) {
            return null;
        }
        return super.render();
    }

    renderOnline() {
        return (
            <div className={'select-language ' + this.props.className}>
                <div className="input__select input__select--clear">
                    <select
                        disabled={this.state.loading}
                        className='input__control'
                        value={this.props.serverLocale}
                        onChange={(e) => this.onSelectLanguage(
                            e.target.value,
                            e.target.options[e.target.selectedIndex].getAttribute('data-js-value')
                        )}
                    >
                    {this.props.languages.map((lang) =>
                        <option
                            data-js-value={lang.js_code}
                            value={lang.code}
                            key={lang.code}
                        >
                            {lang.name}
                        </option>
                    )}
                    </select>
                </div>
            </div>
        );
    }

    renderKiosk = () => {
        if (!this.props.languageModal) {
            return null;
        }
        return (
            <div className="kiosk__modal kiosk-modal--select-language">
                <div className="kiosk__modal__content">
                    <div
                        className={`kiosk__modal__title ${this.state.loading ? "modal-title__with-spinner" : ""}`}
                        onClick={() => this.props.setLanguageModal(false)}>
                            <span>
                                <Translation>{'checkout__select_language'}</Translation> <i className="icon-close" aria-hidden="true" style={{verticalAlign: 'middle'}}></i>
                            </span>
                            {this.state.loading &&
                                <div className="kiosk__spinner">
                                    <Spinner />
                                </div>
                            }
                    </div>
                    <div className="kiosk-select-language__scroll-container">
                        {!this.state.loading && this.props.languages.map((lang) =>
                            <button
                                className={`kiosk-modal__button ${(this.props.locale == lang.js_code) ? "kiosk-modal__button--primary": "kiosk-modal__button--plain"}`}
                                data-js-value={lang.js_code}
                                value={lang.code}
                                key={lang.code}
                                onClick={(e) => this.onSelectLanguage(
                                    e.target.value,
                                    e.target.getAttribute('data-js-value')
                                )}
                            >
                                {lang.name}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        apiUrl: state.ui.apiUrl,
        isEmbed: state.ui.isEmbed,
        locale: state.intl.locale,
        serverLocale: state.intl.serverLocale,
        languages: state.intl.languages,
        loadedTranslationsByServerLocale: state.intl.loadedTranslationsByServerLocale,
        isKiosk: isKioskMode(state)
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setServerLocale,
        setJsLocale,
        changeLocale,
    }, dispatch);
}

SelectLanguage.propTypes = {
    languageModal: PropTypes.bool,
    setLanguageModal: PropTypes.func,
}

SelectLanguage.defaultProps = {
    languageModal: false,
    setLanguageModal: () => {},
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectLanguage)
