// @flow
export const CAPTURE_ONLINE = 1;
export const CAPTURE_KIOSK = 13;
export * from '@showclix-shared/Payment/constants/gatewayTypes';

type Payment = {+id: number, +attributes: Object}

type SetTOSAction = {+type: "SET_TOS", +accepted: boolean};
type AddPaymentAction = {+type: "ADD_PAYMENT", +payment: Payment};

type PaymentErrors = {
  tosAccepted: string;
}

type State = {
    +tosAccepted: boolean,
    +payments: Array<Payment>,
    method: number,
    terminalId: number,
    terminals: Object,
    terminalLocation: string,
    stripeTerminal: Object,
    connectedTerminal: Object,
    isReaderConnected: boolen,
    stripePaymentIntentSecret: string,
    stripePaymentIntentId: string,
    errors: PaymentErrors
}

export type Action = SetTOSAction | AddPaymentAction | SetLeapOptInAction;

let initialState = {
    tosAccepted: false,
    payments: [],
    method : CAPTURE_ONLINE,
    terminalId : '',
    terminalLocation: '',
    terminals: {},
    stripeTerminal: null,
    connectedTerminal: null,
    isReaderConnected: false,
    gatewayType: null,
    gatewayKey: null,
    altGateways: [],
    stripePaymentIntentSecret: null,
    stripePaymentIntentId: null,
    stripeSubmittedPayment: false,
    errors: {}
};

export function payment(state: State = initialState, action:Action): State {
    if(action.type === "SET_TOS") {
        let newState = {...state};
        newState.tosAccepted = action.accepted;
        return newState;
    } else if (action.type === 'SET_PAYMENT_ERROR') {
        return {
          ...state,
          errors: {
            ...state.errors,
            [action.payload.field]: action.payload.message
          }
        }
    } else if(action.type === "ADD_PAYMENT") {
        let newState = {...state};
        newState.payments.push({id: action.payment.id, ...action.payment.attributes});
        return newState;
    } else if(action.type === "SET_CHECKOUT_CONFIG") {
        let newState = {...state};
        let config = action.config;

        if(config.captureMethod) {
            newState.method = config.captureMethod;
        }

        if(config.terminalLocation) {
            newState.terminalLocation = config.terminalLocation;
        }

        return newState;
    } else if(action.type === "SET_TERMINAL_ID") {
        let newState = {...state};
        newState.terminalId = action.terminalId;

        return newState;
    } else if(action.type === "SET_TERMINALS") {
        let newState = {...state};
        newState.terminals = action.terminals;

        return newState;
    } else if(action.type === "SET_STRIPE_TERMINAL") {
        let newState = {...state};
        newState.stripeTerminal = action.stripeTerminal;

        return newState;
    } else if(action.type === "SET_CONNECTED_TERMINAL") {
        let newState = {...state};
        newState.connectedTerminal = action.connectedTerminal;

        return newState;
    } else if(action.type === "SET_IS_READER_CONNECTED") {
        let newState = {...state};
        newState.isReaderConnected = action.isReaderConnected;

        return newState;
    } else if (action.type === "FETCH_EVENT") {
        let newState = {...state};
        let gatewaySettings           = action.data.gatewaySettings;

        if (!gatewaySettings) {
          return newState;
        }

        newState.gatewayType          = gatewaySettings ? gatewaySettings.type         : null;
        newState.gatewayKey           = gatewaySettings ? gatewaySettings.key          : null;
        newState.gatewayLocation      = gatewaySettings ? gatewaySettings.location     : null;
        newState.gatewayOriginUrl     = gatewaySettings ? gatewaySettings.originUrl    : null;
        newState.gatewayCountry       = gatewaySettings ? gatewaySettings.country      : null;
        newState.gatewayLocale        = gatewaySettings ? gatewaySettings.locale       : null;
        newState.gatewayEnvironment   = gatewaySettings ? gatewaySettings.environment  : null;
        newState.jsUrl                = gatewaySettings ? gatewaySettings.jsUrl        : null;
        newState.wallets              = gatewaySettings ? gatewaySettings.wallets      : null;
        return newState;
    } else if (action.type === "FETCH_LISTING_INFO") {
      let newState = {...state};
      let gatewaySettings           = action.data.gatewaySettings;
      newState.gatewayType          = gatewaySettings ? gatewaySettings.type         : null;
      newState.gatewayKey           = gatewaySettings ? gatewaySettings.key          : null;
      newState.gatewayLocation      = gatewaySettings ? gatewaySettings.location     : null;
      newState.gatewayOriginUrl     = gatewaySettings ? gatewaySettings.originUrl    : null;
      newState.gatewayCountry       = gatewaySettings ? gatewaySettings.country      : null;
      newState.gatewayLocale        = gatewaySettings ? gatewaySettings.locale       : null;
      newState.gatewayEnvironment   = gatewaySettings ? gatewaySettings.environment  : null;
      newState.jsUrl                = gatewaySettings ? gatewaySettings.jsUrl        : null;
      newState.wallets              = gatewaySettings ? gatewaySettings.wallets      : null;

      // ALT_GATEWAYS
      newState.altGateways = Array.isArray(action.data.alternateGateways) ? [...action.data.alternateGateways] : [];

      return newState;
    } else if (action.type === "SET_STRIPE_PAYMENT_INTENT_SECRET") {
        let newState = {...state};
        newState.stripePaymentIntentSecret = action.secret;
        return newState;
    } else if (action.type === "SET_STRIPE_PAYMENT_INTENT_ID") {
        let newState = {...state};
        newState.stripePaymentIntentId = action.id;
        return newState;
    }  else if (action.type === "SET_STRIPE_SUBMITTED_PAYMENT") {
        let newState = {...state};
        newState.stripeSubmittedPayment = !!action.val;
        return newState;
    } else {
        return state;
    }
}
