// @flow
import type {SetDataAction, CreateAction, ClearAction} from './reservation';
import { getReducerState, returnReducerState } from './subReducerHelpers';
import type {FetchAction} from './event';
import { SUB_REDUCER_STATE_KEY } from './subReducerHelpers';
import reconcilePersistedState from '../../helpers/reconcilePersistedState';

const DEFAULT_ROUTE_NAME = 'ticketPicker';

export type AlertTypes = "error" | "warning" | "info" | "success";
export type OverlayAction = {+type: "CHECKOUT_OVERLAY", overlay: boolean};
export type RemoveAlertAction = {+type: "REMOVE_ALERT", index: number};
export type AddAlertAction = {+type: "ADD_ALERT", +message: string, +alertType: AlertTypes, +dismissable: boolean};
export type StepCompletedAction = {+type: "SET_STEP_STATUS", +stepType: string, +complete: boolean};
export type NextStepAction = {+type: "NEXT_STEP"};
export type ClearAlertAction = {+type: "CLEAR_ALERTS"};
export type ConfirmationAction = {+type: "GO_TO_CONFIRMATION"};
export type GoToStepAction = {+type: "GO_TO_STEP", +index: number};
export type SetApiUrlAction = {+type: "SET_API_URL", +url: string};
export type SetListingSlugAction = {+type: "SET_LISTING_SLUG", +string: string};
export type SetListingConfigAction = {+type: "SET_LISTING_CONFIG", +listingConfig: Object};
export type SetStepLoadingAction = {+type: "SET_STEP_LOADING", +stepLoading: boolean};

export type Action = OverlayAction | RemoveAlertAction | AddAlertAction | SetDataAction | StepCompletedAction | CreateAction | ClearAction | NextStepAction | GoToStepAction | ConfirmationAction | ClearAlertAction | SetApiUrlAction | SetListingSlugAction | FetchAction | SetListingConfigAction;

//FEE DISPLAY OPTIONS
export const TICKET_FEE_SEPARATE = 1;
export const TICKET_PRICE_ONLY = 2;

// EMBED OPTIONS
export const EMBED_TYPE_BUTTON = 1;
export const EMBED_TYPE_TICKET_LIST = 2;
export const EMBED_TYPE_CALENDAR = 3;
export const EMBED_TYPE_MULTI = 4;
export const EMBED_CART_BEHAVIOR_DELETE_ON_CLOSE = 1;
export const EMBED_CART_BEHAVIOR_PIN_TO_TOP = 2;
export const EMBED_CART_BEHAVIOR_PIN_TO_BOTTOM = 3;
export const EMBED_CART_BEHAVIOR_HIDE = 4;

type State = {
    +overlay: boolean,
    +alerts: Array<Object>,
    +steps: Array<Object>,
    +currentStep: number,
    +confirmation: boolean,
    apiUrl: string,
    listingSlug: string,
    listingId: string,
    listingTemplate: string,
    headerImage: string,
    styleVars: string,
    uiConfig: Object,
    listingConfig: Object,
    currencyConfig: Object,
    stepLoading: boolean,
    checkoutConfig: Object,
    languageModal: boolean, // whether or not the language modal is open
    helpModal: boolean, // whether or not the help modal is open
    cancelConfirm: boolean, //whether or not the cancel confirm is open on kiosk
    managementOpen: boolean, // whether or not the management pane is open
    urlRouting: boolean,
    isRecurring: boolean,
    showCalendarInModal: boolean,
    sellersPreferredLocale: string,
    interactionBlock: boolean,
    collectAddressesOnFree: boolean,
    paymentHandlesSubmission: boolean,
    maintenanceMode: boolean,
    maintenanceModeLoaded: boolean,
    hideBestAvailable: boolean,
    disableBestAvailable: boolean,
    bestAvailableButtonText: string
}

const defaultSeriesOptions = {calendarType: "calendar-button", showSoldOut: false};

export const defaultState = {
    overlay: false,
    alerts: [],
    steps: [],
    currentStep: 0,
    confirmation: false,
    hideCheckoutCallback: null,
    apiUrl: "",
    listingSlug: "",
    listingId: "",
    listingTemplate: "",
    headerImage: "",
    styleVars: "",
    uiConfig: {},
    listingConfig: {},
    currencyConfig: {},
    checkoutConfig: {},
    helpModal: false,
    languageModal: false,
    cancelConfirm: false,
    managementOpen: false,
    urlRouting: true,
    isRecurring: false,
    listingSoldOut: false,
    showCalendarInModal: true,
    sellersPreferredLocale: "en_US",
    interactionBlock: false,
    currentMonth: null, // null | Date object. tracks the month of the series date picker calendar as the user navigates from month to month
    collectAddressesOnFree: true,
    paymentHandlesSubmission: false,
    maintenanceMode: false,
    maintenanceModeLoaded: false,
    showBestAvailablePicker: false,
    showPyosPicker: false,
    currentRouteName: DEFAULT_ROUTE_NAME,
    currentRouteParams: {},
    routesTransitionedTo: [], // keep track of which states we have transitioned to, gets cleared out when reservation clears out
    isEmbed: false,
    embedType: null,
    useFocusTrap: true,
    hideBestAvailable: false,
    disableBestAvailable: false,
    bestAvailableButtonText: ""
}
let initialState = defaultState;

export function ui(state: State = initialState, action: Action): State {
    if(action.type === "CHECKOUT_OVERLAY") {
        let newState = {...state};
        newState.overlay = action.overlay;

        if(action.overlay === false && typeof newState.hideCheckoutCallback === "function") {
            newState.hideCheckoutCallback();
        }

        return newState;
    } else if(action.type === "REMOVE_ALERT") {
        let newState = {...state};
        newState.alerts = [...newState.alerts];
        newState.alerts.splice(action.index, 1);
        return newState;
    } else if(action.type === "ADD_ALERT") {
        let newState = {...state};
        newState.alerts = [...newState.alerts];
        if(Array.isArray(action.message)) {
            for(let i = 0; i < action.message.length; i++) {
                if (!alertAlreadyExists(action.message[i], action.alertType, action.dismissable, newState.alerts)) {
                    newState.alerts.push({
                        message: action.message[i],
                        type: action.alertType,
                        dismissable: action.dismissable,
                        interpolation: action.interpolation,
                    });
                }
            }
        } else {
            if (!alertAlreadyExists(action.message, action.alertType, action.dismissable, newState.alerts)) {
                newState.alerts.push({
                    message: action.message,
                    type: action.alertType,
                    dismissable: action.dismissable,
                    interpolation: action.interpolation,
                });
            }
        }
        return newState;
    } else if(action.type === "CLEAR_ALERTS") {
        let newState = {...state};
        newState.alerts = [];
        return newState;
    } else if(action.type === "TOTAL_COST_CHANGE") {
        let newState = {...state};
        let steps = [...newState.listingConfig.checkoutSteps];
        // skip this for kiosks, and probably box office in the future
        if(!newState.uiConfig.checkout || newState.uiConfig.checkout.captureMethod !== 13) {
            // check to see if there is spend for the checkout
            const amountToCheck = action && action.newTotal && action.newTotal.amount;
            // if the amount is zero or does not exist, there is no spend currently
            const isNoSpend = !amountToCheck;
            // if there is no spend, remove payment step if it exists, and add address step if the setting requires
            // if there is spend, remove address step and add payment
            const stepToAddTitle = isNoSpend ? 'Address' : 'Payment';
            const stepToRemoveTitle = isNoSpend ? 'Payment' : 'Address';
            const shouldAddStep = isNoSpend ? newState.collectAddressesOnFree : true;

            const doesNewStepAlreadyExist = steps.findIndex(step => step && step.title === stepToAddTitle) !== -1;
            const stepToRemoveIndex = steps.findIndex(step => step && step.title === stepToRemoveTitle);
            // add the step to the end of the array, if there is no step to remove
            let newStepIndex = steps.length;

            // if the step to remove exists, remove it and update the target index based on the old step's position
            if (stepToRemoveIndex !== -1) {
                steps.splice(stepToRemoveIndex, 1);
                newStepIndex = stepToRemoveIndex;
            }

            if (shouldAddStep && !doesNewStepAlreadyExist) {
                // add the new step where the old step was, or to the end of the array
                steps.splice(newStepIndex, 0, {
                    title: stepToAddTitle,
                    components: [{ name: stepToAddTitle.toLowerCase() }],
                });
            }
        }

        for(let i = 0; i < newState.currentStep; i++) {
            steps[i].complete = true;
        }

        newState.listingConfig.checkoutSteps = steps;

        return newState;
    } else if(action.type === "SET_STEP_STATUS") {
        let newState = {...state};
        newState.steps = [...newState.steps];
        for(let i = 0; i < newState.steps.length; i++) {
            if(newState.steps[i].type === action.stepType) {
                newState.steps[i].complete = action.complete;
            }
        }
        return newState;
    } else if(action.type === "NEXT_STEP") {
        let newState = {...state};
        if(newState.currentStep < newState.listingConfig.checkoutSteps.length - 1) {
            newState.currentStep++;
        }

        for(let i = 0; i < newState.steps.length; i++) {
            if(i < newState.currentStep) {
                newState.steps[i].complete = true;
            }
        }
        return newState;
    } else if(action.type === "PREV_STEP") {
      const newState = {...state};

      const { currentStep } = newState;

      newState.currentStep = Math.max(0, currentStep - 1);

      return newState;
    } else if(action.type === "GO_TO_STEP") {
        let newState = {...state};
        if(action.index > newState.listingConfig.checkoutSteps.length - 1) {
            return state;
        }

        newState.currentStep = action.index;
        return newState;
    } else if(action.type === "SET_STEP_COMPLETE") {
        let newState = {...state};
        newState.listingConfig = {...newState.listingConfig};
        newState.listingConfig.checkoutSteps[action.index].complete = action.value;
        return newState;
    } else if(action.type === "SET_CONFIRMATION") {
        let newState = {...state};
        newState.confirmation = action.confirmation;
        return newState;
    } else if(action.type === "GO_TO_CONFIRMATION") {
        let newState = {...state};
        newState.confirmation = true;
        newState.alerts = [];
        return newState;
    } else if(action.type === "RESERVATION_CLEARED") {
        let newState = {...state};
        newState.currentStep = 0;
        newState.currentMonth = null;
        if(action.reset) {
            newState.alerts = [];
        }
        newState.confirmation = false; // just in case it's somehow still set
        newState.showCalendarInModal = initialState.showCalendarInModal;
        if (hasCheckoutSteps(newState)) {
            newState = {
                ...newState,
                listingConfig: {
                    ...newState.listingConfig,
                    checkoutSteps: newState.listingConfig.checkoutSteps.map(step => {return {...step, complete: false}}),
                }
            };
        }
        if (newState[SUB_REDUCER_STATE_KEY] !== undefined) {
            delete newState[SUB_REDUCER_STATE_KEY];
        }
        if (!!action.reset) {
            newState.showBestAvailablePicker = false;
            newState.showPyosPicker = false;
            newState.currentRouteName = DEFAULT_ROUTE_NAME;
            newState.currentRouteParams = {};
        }
        newState.routesTransitionedTo = [];
        return newState;
    } else if(action.type === "RESERVATION_COMPLETED") {
        let newState = {...state};
        newState.currentMonth = null;
        if (hasCheckoutSteps(newState)) {
            newState = {
                ...newState,
                listingConfig: {
                    ...newState.listingConfig,
                    checkoutSteps: newState.listingConfig.checkoutSteps.map(step => {return {...step, complete: false}}),
                }
            };
        }
        if (newState[SUB_REDUCER_STATE_KEY] !== undefined) {
            delete newState[SUB_REDUCER_STATE_KEY];
        }
        newState.showBestAvailablePicker = false;
        newState.showPyosPicker = false;
        newState.routesTransitionedTo = [];
        return newState;
    } else if(action.type === "RESERVATION_CREATED") {
        let newState = {...state};
        newState.currentStep = 0;
        newState.alerts = [];
        return newState;
    } else if(action.type === "SET_API_URL") {
        let newState = {...state};
        newState.apiUrl = action.url;
        return newState;
    } else if(action.type === "SET_LISTING_ID") {
        let newState = {...state};
        newState.listingId = action.id;
        return newState;
    } else if(action.type === "SET_LISTING_SLUG") {
        let newState = {...state};
        // in this case we were on one listing and are now loading another one
        if(action.slug !== newState.listingSlug) {
            newState.overlay = false;
        }

        newState.listingSlug = action.slug;
        return newState;
    } else if(action.type === "SET_CHECKOUT_CONFIG") {
        let newState = {...state};
        let config = Array.isArray(action.config) ? {} : action.config;

        // apply defaults if series
        if(newState.isRecurring && !config.seriesOptions) {
            config.seriesOptions = {...defaultSeriesOptions};
        }

        newState.checkoutConfig = config;
        return newState;
    } else if(action.type === "SET_LISTING_CONFIG") {
        let newState = {...state};
        newState.listingConfig = action.listingConfig;

        if(action.listingConfig.styles) {
            newState.styleVars = action.listingConfig.styles;
        }

        let config = {...newState.uiConfig};
        newState.urlRouting = action.listingConfig.urlRouting;
        newState.uiConfig = Object.assign(config, action.listingConfig);

        return newState;
    } else if(action.type === "FETCH_EVENT") {
        let newState = {...state};
        newState.currencyConfig = action.data.attributes.currencyConfig;
        newState.isRecurring = Object.values(action.data.attributes.events)[0].event.isRecurring;
        newState.listingSoldOut = action.data.attributes.listingSoldOut;
        newState.collectAddressesOnFree = action.data.attributes.settings.collectAddressesOnFree;

        // apply defaults if not yet applied
        if (
            newState.isRecurring &&
            typeof newState.checkoutConfig === 'object' &&
            newState.checkoutConfig !== null &&
            !newState.checkoutConfig.seriesOptions
        ) {
            const newCheckoutConfig = {...newState.checkoutConfig};
            newCheckoutConfig.seriesOptions = {...defaultSeriesOptions};
            newState.checkoutConfig = newCheckoutConfig;
        }

        return newState;
    } else if(action.type === "REFRESH_EVENT") {
        let newState = {...state};
        newState.listingSoldOut = action.data.attributes.listingSoldOut;
        return newState;
    } else if(action.type === "FETCH_LISTING_INFO") {
        let newState = {...state};
        newState.listingSoldOut = action.data?.event?.attributes?.listingSoldOut;

        // maintenanceMode
        newState.maintenanceMode = action.data?.maintenance?.maintenanceMode;
        newState.maintenanceModeLoaded = true;

        // customerCare
        newState.uiConfig = {
            ...state.uiConfig,
            customerCare: action.data.customerCare
        }

        return newState;
    } else if(action.type === "RESET_UI") {
        let newState = {...state};
        newState.listingConfig = initialState.listingConfig;
        newState.styleVars = initialState.styles;
        newState.checkoutConfig = {};
        newState.stepLoading = false;
        newState.interactionBlock = false;
        newState.currentMonth = null;
        newState.listingId = "";

        return newState;
    } else if(action.type === "SET_HELP_OPEN") {
        let newState = {...state};
        newState.helpModal = action.value;
        return newState;
    } else if(action.type === "SET_LANGUAGE_MODAL") {
        let newState = {...state};
        newState.languageModal = action.value;
        return newState;
    } else if(action.type === "SET_CANCEL_CONFIRM") {
        let newState = {...state};
        newState.cancelConfirm = action.value;
        return newState;
    } else if(action.type === "SET_MANAGEMENT_OPEN") {
        let newState = {...state};
        newState.managementOpen = action.value;
        return newState;
    } else if(action.type === "SET_DEFAULT_SHOW_CALENDAR_IN_MODAL") {
        initialState.showCalendarInModal = action.value;
        return {...state};
    } else if(action.type === "SET_SHOW_CALENDAR_IN_MODAL") {
        let newState = {...state};
        newState.showCalendarInModal = action.value;
        return newState;
    } else if(action.type === "SET_INTERACTION_BLOCK") {
        let newState = {...state};
        newState.interactionBlock = action.value;
        return newState;
    } else if(action.type === "SET_CURRENT_MONTH") {
        let newState = getReducerState(state, defaultState, action);
        newState.currentMonth = action.value;
        return returnReducerState(state, newState, action);
    } else if(action.type === "SET_PAYMENT_HANDLES_SUBMISSION") {
        let newState = {...state};
        newState.paymentHandlesSubmission = action.value;
        return newState;
    } else if(action.type === "UI_SET_SHOW_BEST_AVAILABLE_PICKER") {
        let newState = {...state};
        newState.showBestAvailablePicker = !!action.val;
        return newState;
    } else if(action.type === "UI_SET_SHOW_PYOS_PICKER") {
        let newState = {...state};
        newState.showPyosPicker = !!action.val;
        return newState;
    } else if(action.type === "@ui-router/FINISH_TRANSITION") {
        let newState = {...state};
        const route = action?.transition?._targetState?._definition?.name;
        const routeParams = action?.transition?._targetState?._params;
        if (!Array.isArray(newState.routesTransitionedTo)) {
            newState.routesTransitionedTo = [];
        }
        if (route) {
            newState.currentRouteName = route;
            newState.currentRouteParams = typeof routeParams === 'object' ? routeParams : null;
            if (!newState.routesTransitionedTo.includes(route)) {
                newState.routesTransitionedTo.push(route);
            }
        }
        return newState;
    } else if(action.type === "UI_SET_USE_FOCUS_TRAP") {
        let newState = {...state};
        newState.useFocusTrap = !!action.val;
        return newState;
    } else if(action.type === "UI_SET_IS_EMBED") {
        let newState = {...state};
        newState.isEmbed = !!action.val;
        return newState;
    } else if(action.type === "UI_SET_EMBED_TYPE") {
        let newState = {...state};
        newState.embedType = action.val;
        return newState;
    } else if (action.type === 'RECONCILE_PERSISTED_STATE') {
        return reconcilePersistedState(state, defaultState);
    } else if (action.type === 'UI_SET_HIDE_BEST_AVAILABLE') {
        let newState = {...state};
        newState.hideBestAvailable = !!action.val;
        return newState;
    } else if (action.type === 'UI_SET_DISABLE_BEST_AVAILABLE') {
        let newState = {...state};
        newState.disableBestAvailable = !!action.val;
        return newState;
    } else if (action.type === 'UI_SET_BEST_AVAILABLE_BUTTON_TEXT') {
        let newState = {...state};
        newState.bestAvailableButtonText = action.val;
        return newState;
    } else {
        return state;
    }
}

/**
 * return true if checkoutSteps exist in state
 */
const hasCheckoutSteps = state => {
    return state.listingConfig && state.listingConfig.checkoutSteps && Array.isArray(state.listingConfig.checkoutSteps);
}

const alertAlreadyExists = (message, type, dismissable, currentAlerts) => {
    for (let i = 0; i < currentAlerts.length; i++) {
        if (currentAlerts[i].message === message && currentAlerts[i].type === type && currentAlerts[i].dismissable === dismissable) {
            return true;
        }
    }
    return false;
}
