import {createSelector} from 'reselect';
import {toDecimals, toMinorUnit} from 'checkout/helpers/currency';

const getReservation = state => state.reservation;
const getFeeState = state => state.fees;
const getTickets = state => state.tickets;
const getProducts = state => state.productSales;
const getBundles = state => state.bundleSales;
const getDelivery = state => state.delivery;
const getDonation = state => state.donations;
const getInsurance = state => state.insurance;
const getEvent = state => state.event;
const getInsuranceFeeName = state => state.ui?.checkoutConfig?.insuranceFeeName ?? 'Insurance Fee';

export const numberOfItemsInCart = createSelector(
    [getReservation],
    (reservation) => {
        const ticket_count = !!reservation.ticket_count ? +reservation.ticket_count : 0;
        const product_count = !!reservation.product_count ? +reservation.product_count : 0;
        const bundle_count = !!reservation.bundle_count ? +reservation.bundle_count : 0;
        return ticket_count + product_count + bundle_count;
    }
)

export const reservationActive = createSelector(
    [getReservation],
    (reservation) => {
        return (reservation.token !== '');
    }
)

export const isReservationFree = createSelector(
    [getReservation],
    (reservation) => {
        if(!reservation.total_cost) {
            return false;
        }

        return (reservation.total_cost.amount === 0);
    }
)

export const isReservationContentFree = createSelector(
    [getReservation],
    (reservation) => {
        if(reservation.ticket_total.amount > 0) {
            return false;
        }

        if(reservation.product_total.amount > 0) {
            return false;
        }

        if(reservation.bundle_total.amount > 0) {
            return false;
        }

        return true;
    }
)

export const reservationQualifiesForInsurance = createSelector([getReservation, getInsurance], (reservation, insurance) => {
    if(insurance.provider && !(isReservationContentFree({reservation: reservation}) || isReservationFree({reservation: reservation}))) {
        return true;
    }
    return false;
});

export const getInsuranceItems = createSelector(
    [getReservation, getTickets, getProducts, getBundles, getFeeState],
    (reservation, tickets, products, bundles, fees) => {
        let items = [];

        // ticket items
        for(let i = 0; i < tickets.tickets.length; i++) {
            items.push(tickets.tickets[i].cost.amount + tickets.tickets[i].buyer_fee.amount);
        }

        // products
        let productSales = Object.values(products.products);
        for(let i = 0; i < productSales.length; i++) {
            items.push(productSales[i].cost.amount + productSales[i].shipping_fee.amount + productSales[i].buyer_fee.amount);
        }

        // bundles
        let bundleSales = Object.values(bundles.bundles);
        for(let i = 0; i < bundleSales.length; i++) {
            let numContents = 0;
            if(bundleSales[i].contents.tickets && !Array.isArray(bundleSales[i].contents.tickets)) {
                let bundleTickets = Object.values(bundleSales[i].contents.tickets);
                for(let i = 0; i < bundleTickets.length; i++) {
                    numContents += +bundleTickets[i].quantity;
                }
            }

            if(bundleSales[i].contents.products && !Array.isArray(bundleSales[i].contents.products)) {
                let products = Object.values(bundleSales[i].contents.products);
                for(let i = 0; i < products.length; i++) {
                    numContents += +Object.values(products[i])[0].quantity;
                }
            }

            let totalPrice = bundleSales[i].cost.amount + bundleSales[i].buyer_fee.amount;

            if (numContents > 0) {

                const priceOverNumContents = Math.floor(totalPrice / numContents);

                for(let i = 0; i < numContents; i++) {

                    items.push(priceOverNumContents);

                }

            }

        }

        let feeAmount = 0;
        for(let i = 0; i < reservation.additional_fees.length; i++) {
            feeAmount += reservation.additional_fees[i].fee_details.feeAmount;
        }

        for(let i = 0; i < items.length; i++) {
            if(feeAmount > 0) {
                items[i] = items[i] + Math.floor(feeAmount / items.length);
            }

            items[i] = toDecimals({amount: items[i], currency: reservation.total_cost.currency});
        }

        return items;
    }
);

export const isReservationEmpty = createSelector(
    [getReservation],
    (reservation) => {
        if(reservation.items_count > 0) {
            return false;
        }

        return true;
    }
);

export const isReservationFullyPaid = createSelector(
    [getReservation],
    (reservation) => {
        let isPaid = false;

        if(reservation.amount_remaining && reservation.amount_remaining.amount === 0) {
            isPaid = true;
        }

        return isPaid;
    }
);

export const getAdditionalFees = createSelector(
    [getReservation, getFeeState, getProducts, getDelivery, getDonation, getBundles, getInsuranceFeeName],
    (reservation, fees, products, delivery, donation, bundles, insuranceFeeName) => {
        if(!Array.isArray(reservation.additional_fees)) {
            return [];
        }

        let addlFees = [];

        // products
        let productTax = 0;
        let productSales = Object.values(products.products);
        for(let i = 0; i < productSales.length; i++) {
            if(productSales[i].tax && productSales[i].tax.amount && productSales[i].tax.amount > 0) {
                productTax += productSales[i].tax.amount;
            }
        }
        let bundleSales = Object.values(bundles.bundles);
        for(let i = 0; i < bundleSales.length; i++) {
            Object.values(bundleSales[i].contents.products).forEach(configs => {
               Object.values(configs).forEach(bundleProduct => {
                   if (+bundleProduct.tax_total) {
                       productTax += toMinorUnit(+bundleProduct.tax_total, reservation.currency);
                   }
               });
            });
        }
        if(productTax > 0){
            addlFees.push({
                name: "Product Tax",
                fee_details: {
                    amount: productTax,
                    currency: reservation.currency,
                    symbol: reservation.currency_symbol,
                }
            })
        }

        //product shipping fees
        if (reservation.product_shipping_fees && reservation.product_shipping_fees.amount && reservation.product_shipping_fees.amount > 0) {
            addlFees.push({
                name: "Product Shipping Fees",
                fee_details: {
                    amount: reservation.product_shipping_fees.amount,
                    currency: reservation.currency,
                    symbol: reservation.currency_symbol,
                }
            })

        }

        //delivery fee
        let deliveryFee = null;

        if (delivery.id !== null && delivery.price && delivery.price.amount >= 0) {
            deliveryFee = delivery.price.amount;
        } else if(reservation.total_delivery_fees && reservation.total_delivery_fees.amount) {
            deliveryFee = reservation.total_delivery_fees.amount;
        }

        if (deliveryFee && deliveryFee > 0) {
            addlFees.push({
                name: "Delivery Fee",
                fee_details: {
                    amount: deliveryFee,
                    currency: reservation.currency,
                    symbol: reservation.currency_symbol,
                }
            });
        }

        //donation
        if (!isNaN(donation.totalReservationDonation) && donation.totalReservationDonation > 0) {
            addlFees.push({
                name: "Donation",
                fee_details: {
                    amount: donation.totalReservationDonation,
                    currency: reservation.currency,
                    symbol: reservation.currency_symbol,
                }
            });
        }

        //insurance
        let hadInsurance = false;

        for(let i = 0; i < reservation.additional_fees.length; i++) {
            if(reservation.additional_fees[i].name === insuranceFeeName && Object.keys(fees.insuranceFee).length > 0) {
                hadInsurance = true;
                if(fees.insuranceFee.amount === 0) {
                    continue;
                }

                addlFees.push({name: insuranceFeeName, fee_details:{amount: fees.insuranceFee.amount, currency: reservation.currency, symbol: reservation.currency_symbol}});
                continue;
            }

            //any other fees
            if (reservation.additional_fees[i].fee_details) {
                if(reservation.additional_fees[i].fee_details.amount === 0) {
                    continue;
                }

                //these fee types are handled separately
                const reserved = ["Product Shipping Fees", "Delivery Fee"];

                if (reserved.indexOf(reservation.additional_fees[i].name) !== -1) {
                    continue;
                }

                addlFees.push({name: reservation.additional_fees[i].name, fee_details:{amount: reservation.additional_fees[i].fee_details.amount, currency: reservation.currency, symbol: reservation.currency_symbol}});
                continue;
            }
        }

        // since insurance doesn't always show up on the reservation we may need to add it manually
        if(!hadInsurance && Object.keys(fees.insuranceFee).length > 0 && fees.insuranceFee.amount > 0) {
            addlFees.push({name: insuranceFeeName, fee_details:{amount: fees.insuranceFee.amount, currency: reservation.currency, symbol: reservation.currency_symbol}});
        }

        //sort with the following first to create a consistent ordering:
        const sortSearch = [insuranceFeeName, "Delivery Fee", "Product Tax", "Product Shipping Fees", "Donation"];

        let sortedFees = [];
        sortSearch.forEach(searchString => {
            addlFees.forEach(fee => {
                if (fee.name === searchString) {
                    sortedFees.push(fee);
                }
            });
        });

        addlFees.forEach(fee => {
            if (sortSearch.indexOf(fee.name) === -1) {
                sortedFees.push(fee);
            }
        });

        return sortedFees;
    }
);

export const getServiceFee = createSelector(
    [getReservation, getInsuranceFeeName],
    (reservation, insuranceFeeName) => {
        if(!Array.isArray(reservation.additional_fees)) {
            return {};
        }

        let additionalFees = 0;
        for(let i = 0; i < reservation.additional_fees.length; i++) {
            if (reservation.additional_fees[i].name !== insuranceFeeName && reservation.additional_fees[i].name !== "Delivery Fee") {
                if (reservation.additional_fees[i].fee_details.rolledUpFee == true) {
                    additionalFees += reservation.additional_fees[i].fee_details.amount;
                }
            }
        }

        return {
            amount: reservation.buyer_service_fee.amount + additionalFees,
            currency: reservation.buyer_service_fee.currency,
            symbol: reservation.buyer_service_fee.symbol,
        };
    }
);

/**
 * Get the unique event IDs from all tickets, products, and bundles in the cart
 */
export const getEventIdsFromReservedItems = createSelector(
    [getTickets, getProducts, getBundles],
    (tickets, products, bundles) => {
        const eventIds = [];
        [tickets.tickets, products.products, bundles.bundles].forEach(itemArray => {
            itemArray.forEach(item => {
                if (+item.event_id && eventIds.indexOf(+item.event_id) === -1) {
                    eventIds.push(+item.event_id);
                }
            });
        });
        return eventIds;
    }
);

/**
 * Get the reservation event ID from the listing event or selected child event
 */
export const getReservationEventId = createSelector([getEvent],(eventState) => {
        let reservationEventId = null;
        if (!!eventState?.childEvent?.event_id) {
            reservationEventId = eventState.childEvent.event_id;
        } else if (typeof eventState.events === 'object' && eventState.events !== null && !!Object.keys(eventState.events).length) {
          const target = eventState.fetchedEventId
            ? eventState.events[eventState.fetchedEventId]
            : Object.values(eventState.events)[0];

          reservationEventId = target?.event?.event_id ?? null;
        }
        return reservationEventId;
    }
);

export const getIsNameFieldDisabled = createSelector(
  [getReservation],
  (reservation) => {
    if (!reservation.membership_autofill) {
      return false;
    }

    const { firstName, lastName } = reservation.membership_autofill;

    // Security measures in case when first or last name are not defined in membership autofill
    if (!firstName && !lastName) {
      return false;
    }

    return true;
  }
);

export const getIsEmailFieldDisabled = createSelector(
  [getReservation],
  (reservation) => {
    if (!reservation.membership_autofill) {
      return false;
    }

    const { email, lockEmail } = reservation.membership_autofill;

    // Security measures in case when email is not defined in membership autofill
    if (!email) {
      return false;
    }

    return lockEmail;
  }
)
