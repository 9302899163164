import React, { useMemo } from "react"
import Phone from '@showclix-shared/CheckoutProcess/components/fields/Phone';
import { countryPhoneCodes as codes } from '@showclix-shared-util/countryPhoneCodes';

export const PHONE_STRING_SEPARATOR = ':';
const FALLBACK_COUNTRY_CODE = 1;

const removePlus = (str) => str.replace(/\+/g, '');

const concatenatePhone = (code, phone = '') => {
    const pureCode = removePlus(code) || FALLBACK_COUNTRY_CODE;

    return `+${pureCode}${PHONE_STRING_SEPARATOR}${phone}`;
};

const parsePhoneString = (phoneString) => {
    if (phoneString.includes(PHONE_STRING_SEPARATOR)) {
        const [code, phone] = phoneString.split(PHONE_STRING_SEPARATOR);
        return { countryCode: removePlus(code), phone };
    }

    // handle case when user proceed to payment and decided to go back
    // in that case the phone number will be modified since it gets modified
    // before request sent so it has a correct pattern
    // in this case we don't have a separator (:) anymore
    // so we need to find the correct country code and then split it into two parts
    const purePhoneString = removePlus(phoneString);

    const code = codes.find(c => purePhoneString.startsWith(c));
    
    if (!code) {
        return { countryCode: '', phone: '' }
    }

    return {
        countryCode: purePhoneString.slice(0, code.length),
        phone: purePhoneString.slice(code.length)
    }
}

export const PhoneQuestion = ({
    question,
    required,
    textQuestionValue,
    onAnswerUpdate,
    onFocus: onFocusProp,
    hasError,
    onBlur
}) => {
    const onFocus = (e) => {
        onFocusProp?.(e, question)
    }

    const onPhoneChange = (phone) => {
        const { countryCode } = parsePhoneString(textQuestionValue);
        onAnswerUpdate(concatenatePhone(countryCode, phone));
    }

    const onCountryCodeChange = (code) => {
        const { phone } = parsePhoneString(textQuestionValue);
        onAnswerUpdate(concatenatePhone(code, phone));
    }

    const { countryCode, phone } = useMemo(() => parsePhoneString(textQuestionValue), [textQuestionValue]);

    return (
        <Phone
            countryCode={countryCode}
            phone={phone}
            onPhoneChange={onPhoneChange}
            onCountryCodeChange={onCountryCodeChange}
            onFocus={onFocus}
            required={required}
            hideRequiredLabel
            hasError={hasError}
            onBlur={onBlur}
        />
    )
}