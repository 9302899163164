import {getCheckoutClient} from "../config/CheckoutClient";
import {confirmationSlice} from "../slices/confirmation";

export function getConfirmationData(token, secret) {
    return (dispatch, getState) => {
        let state = getState();
        return getCheckoutClient(state.ui.apiUrl).reservationGetRequest(token, 'confirmation', secret).then((result) => {
            dispatch(confirmationSlice.actions.setConfirmationEvents({events: result.body.data.attributes.events}));
            dispatch(confirmationSlice.actions.setReceiptUrl({url: result.body.data.attributes.receiptUrl}));
            dispatch(confirmationSlice.actions.setDeliveryMethodName({deliveryMethodName: result.body.data.attributes.deliveryMethodName}));
            dispatch(confirmationSlice.actions.setConfirmationNumber({confirmationNumber: result.body.data.attributes.confirmationNumber}));
            dispatch(confirmationSlice.actions.setRoktPayload({roktPayload: result.body.data.attributes.roktPayload}));
        }).catch((error) => {
            return Promise.reject();
        });
    }
}
